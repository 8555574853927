import React from 'react';
import { Form, Input,message,Button,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'

const FormItem=Form.Item;
const { Option } = Select;


class GenericSubdormainCreationForm extends React.Component {

  state = {
    sub_name:'' ,  
    ip_address:''
  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    let form_data = new FormData();
    form_data.append('sub_name', this.state.sub_name);
    form_data.append('ip_address', this.state.ip_address);

    axios.post(serverconfig.backendserverurl+'/customqueries/create_generic_subdormain', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{
    message.info(res.data.message)

    window.location.reload(false)

  }
  )
  .catch(error => console.log(error))


  }

  handlesub_nameChange= (e) => this.setState({ sub_name: e.target.value});
  handleip_addressChange= (e) => this.setState({ ip_address: e.target.value});


  render(){
    return (
      <div>
        <Form onFinish={(event) => this.handleFormSubmit(event)}>
          <FormItem label="Subdormain Name"
          name="sub_name"
          rules={[
            {
              required: true,
              message: 'Please input name',
            },
          ]}          
          >
            <Input  placeholder="Put a name here." 
            value={this.state.sub_name}
             onChange={this.handlesub_nameChange} />
          </FormItem>

          <FormItem label="Ip Address"
          name="ip_address"
          rules={[
            {
              required: true,
              message: 'Please input ip_address',
            },
          ]}          
          >
            <Input  placeholder="IP address." 
            value={this.state.ip_address} 
            onChange={this.handleip_addressChange} />
          </FormItem>

          <FormItem>
            <Button  type="primary" htmlType="submit">Create</Button>
          </FormItem>
        </Form>
      </div>
    );
  }

}


export default GenericSubdormainCreationForm;



